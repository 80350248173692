import React, { useState, useEffect } from 'react';
import { Form, Select, Button, message, Upload, Modal } from 'antd';
import { FileExcelTwoTone } from '@ant-design/icons';
import './../ClickerQuiz/style.scss';
import endpoints from 'v2/config/endpoints';
import { useSelector } from 'react-redux';
import axiosInstance from 'v2/config/axios';
import { getArrayValues } from 'utility-functions';
import FileSaver from 'file-saver';

const ClickerAssignUsersFilters = ({
  createQuestionmodalData,
  filterData,
  setFilterData,
  getListData,
  bulkUpload,
}) => {
  const { Option } = Select;

  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );

  //Filters dropdown State
  const [branchList, setBranchList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(null);
  const [bulkuploadModal, setBulkuploadModal] = useState(false);
  const [sectionMappingId, setSectionMappingId] = useState([]);

  //UseEffect
  useEffect(() => {
    console.log({ filterData, createQuestionmodalData });
  });

  //Default
  useEffect(() => {
    getBranchList(selectedAcademicYear?.id);
  }, []);

  //grade
  useEffect(() => {
    if (filterData?.branch) {
      getGradeList(selectedAcademicYear?.id, filterData?.branch?.branch?.id);
    }
  }, [filterData?.branch]);

  //Section
  useEffect(() => {
    if (filterData?.branch && filterData?.grade) {
      getSectionList(
        selectedAcademicYear?.id,
        filterData?.branch?.branch?.id,
        filterData?.grade?.grade_id
      );
    }
  }, [filterData?.branch, filterData?.grade]);

  //GET API

  //branch
  const getBranchList = (id) => {
    axiosInstance
      .get(endpoints.academics.branches, {
        params: { session_year: id ? id : selectedAcademicYear.id },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setBranchList(result.data?.data?.results);
        }
      })
      .catch((error) => {});
  };

  //grade
  const getGradeList = (academicYearId, branchId) => {
    axiosInstance
      .get(endpoints.academics.grades, {
        params: {
          session_year: academicYearId ? academicYearId : selectedAcademicYear?.id,
          branch_id: branchId ? branchId : filterData?.branch?.branch?.id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeList(result.data?.data);
        }
      })
      .catch((error) => {});
  };

  //section
  const getSectionList = (sessionYear, branchId, gradeId) => {
    axiosInstance
      .get(endpoints.academics.sections, {
        params: {
          session_year: sessionYear ? sessionYear : selectedAcademicYear.id,
          branch_id: branchId ? branchId : filterData?.branch?.branch?.id,
          grade_id: gradeId ? gradeId : filterData.grade?.grade_id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setSectionList(result.data?.data);
        }
      })
      .catch((error) => {});
  };

  const excelDownload = (data, filename) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(blob, filename);
  };

  const downloadBulkTemplate = () => {
    let param = {
      section_mappings: sectionMappingId ? sectionMappingId.join(',') : [],
    };

    if (sectionMappingId?.length < 1) {
      message.error('Please select section to download sample template');
      return;
    }

    axiosInstance
      .post(`${endpoints.clicker.clickerBulkUpload}`, param, {
        responseType: 'blob',
      })
      .then((res) => {
        excelDownload(res.data, `clicker_bulk_upload.xlsx`);
      })
      .catch((error) => {
        message.error('Something went wrong');
      });
  };

  // handle Filter
  const handleFilterChange = (keyname, value) => {
    if (keyname === 'branch') {
      setFilterData({
        ...filterData,
        branch: value ? value.optionsData : null,
        grade: null,
        section: [],
      });
      setGradeList([]);
      setSectionList([]);
      return;
    }
    if (keyname === 'grade') {
      setFilterData({
        ...filterData,
        grade: value ? value.optionsData : null,
        section: [],
      });

      setSectionList([]);
      return;
    }
    if (keyname === 'section') {
      setFilterData({
        ...filterData,
        section: value ? getArrayValues(value, 'optionsData') : [],
      });
      return;
    }

    setFilterData({ ...filterData, [keyname]: value?.optionsData });
  };

  const handleSectionMapping = (value) => {
    setSectionMappingId(value?.map((e) => e?.id));
  };

  const branchOptions = branchList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.branch?.branch_name}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.grade_id} id={el?.grade_id} value={el.grade_id} optionsData={el}>
        {el?.grade_name}
      </Option>
    );
  });

  const sectionOptions = sectionList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.sec_name}
      </Option>
    );
  });

  const allowedFiles = ['.xls', '.xlsx'];
  const { Dragger } = Upload;
  const draggerProps = {
    showUploadList: false,
    disabled: false,
    accept: allowedFiles.join(),
    // '.xls,.xlsx',
    multiple: false,
    onRemove: () => {
      setSelectedFile(null);
    },
    onDrop: (e) => {
      const file = e.dataTransfer.files;
      setSelectedFile(null);
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      if (allowedFiles.includes(type)) {
        setSelectedFile(...file);
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
      }

      return false;
    },
    beforeUpload: (...file) => {
      setSelectedFile(null);
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      if (allowedFiles.includes(type)) {
        setSelectedFile(...file[1]);
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
      }

      return false;
    },
    selectedFile,
  };

  const hanldleUpload = () => {
    if (!selectedFile) {
      message.error('Please select file !!');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    setUploading(true);

    axiosInstance
      .put(endpoints.clicker.clickerBulkUpload, formData, {
        responseType: 'blob',
      })
      .then((response) => {
        if (response?.status === 200) {
          setBulkuploadModal(false);
          setSectionMappingId([]);
          setSelectedFile(null);
          message.success('Clicker id uploaded successfully');
          excelDownload(response?.data, 'clicker_bulk_uploaded_status.xlsx');
        } else {
          message.error(response?.data?.developer_msg ?? 'Something went wrong');
        }
        setUploading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong!!');
        setUploading(false);
      });
  };

  return (
    <>
      <Form layout='vertical' className='th-clicker-form'>
        <div className='row align-items-center'>
          <div className='col-sm-2 col-6 mb-2'>
            <label htmlFor='branch' className='' title='Branch*'>
              Branch
            </label>
            <Select
              showSearch
              placeholder='Select Branch*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              l
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              value={filterData?.branch?.id ?? null}
              onChange={(e, row) => handleFilterChange('branch', row, 'quiz')}
            >
              {branchOptions}
            </Select>
          </div>
          <div className='col-sm-2 col-6 mb-2'>
            <label htmlFor='grade' className='' title='Grade*'>
              Grade
            </label>
            <Select
              showSearch
              placeholder='Select Grade*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              l
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              value={filterData?.grade?.grade_id ?? null}
              onChange={(e, row) => handleFilterChange('grade', row, 'quiz')}
            >
              {gradeOptions}
            </Select>
          </div>
          <div className='col-sm-2 col-6 mb-2'>
            <label htmlFor='section' className='' title='Section*'>
              Section
            </label>
            <Select
              showSearch
              placeholder='Select Section'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              mode='multiple'
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              maxTagCount={1}
              value={filterData?.section ? getArrayValues(filterData?.section, 'id') : []}
              onChange={(e, row) => handleFilterChange('section', row, 'quiz')}
            >
              {sectionOptions}
            </Select>
          </div>
          <div className='col-sm-2 col-6'>
            <Button
              type='primary'
              className='th-br-4 w-100 mt-3'
              loading={filterData?.loading}
              onClick={() => getListData()}
            >
              Filter
            </Button>
          </div>
          <div className='col-sm-2 col-6'>
            {bulkUpload?.visible && filterData?.grade ? (
              <>
                <Button
                  type='primary'
                  className='th-br-4 w-100 mt-3'
                  onClick={() => setBulkuploadModal(true)}
                >
                  Bulk Upload
                </Button>

                <Modal
                  title='Bulk clicker id upload'
                  visible={bulkuploadModal}
                  onCancel={() => {
                    setBulkuploadModal(false);
                    setSectionMappingId([]);
                  }}
                  footer={false}
                  className='th-modal'
                >
                  <div className='row justify-content-center th-width-100'>
                    <div className='col-12 th-bg-white py-2 th-br-5 px-0'>
                      <div className=' pt-2'>
                        <Dragger
                          {...draggerProps}
                          className={`th-br-4 th-bg-white d-flex justify-content-center th-width-100`}
                          style={{
                            background: '#fff6f6',
                          }}
                        >
                          {selectedFile ? (
                            <div
                              className='row align-items-center'
                              style={{ height: '100px' }}
                            >
                              {/* <div className='col-2 pr-0'>Selected File :</div> */}
                              <div className='col-12 text-wrap'>
                                <span className='th-fw-500 th-16'>
                                  <FileExcelTwoTone className='pr-2' />
                                  {selectedFile?.name}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <p className='pt-2'>
                                Drag And Drop Files Here <br /> or
                              </p>
                              <Button
                                className='th-primary pb-2 mt-0 th-bg-white th-br-4'
                                style={{ border: '1px solid #1b4ccb' }}
                              >
                                Browse Files
                              </Button>
                            </>
                          )}
                        </Dragger>
                      </div>
                      {fileTypeError && (
                        <div className='py-3'>
                          <div className='row pt-3 justify-content-center  th-red'>
                            <span className='th-red th-fw-500 th-16'>
                              Please select Excel file only
                            </span>
                          </div>
                        </div>
                      )}
                      <div className='row align-items-center py-2 mt-2'>
                        <div className='col-6 px-0'>
                          <Select
                            showSearch
                            placeholder='Select Section'
                            optionFilterProp='children'
                            className='w-100'
                            allowClear
                            getPopupContainer={(trigger) => trigger.parentNode}
                            mode='multiple'
                            dropdownMatchSelectWidth={false}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            maxTagCount={1}
                            value={sectionMappingId ?? []}
                            onChange={(e, row) => handleSectionMapping(row)}
                          >
                            {sectionOptions}
                          </Select>
                        </div>
                        <div className='col-6 pr-0'>
                          <Button
                            type='primary'
                            className='th-bg-pink th-primary th-br-4'
                            onClick={downloadBulkTemplate}
                          >
                            Download Sample File
                          </Button>
                        </div>
                      </div>
                      <div className='d-flex float-right py-2 py-sm-0 mt-2'>
                        <Button
                          type='default'
                          className='mx-md-4 mx-2 th-br-4 px-4'
                          onClick={() => setSelectedFile(null)}
                        >
                          Clear
                        </Button>
                        <Button
                          type='primary'
                          className='mx-md-0 mx-2 th-br-4 px-4'
                          onClick={hanldleUpload}
                          disabled={uploading || !selectedFile}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </>
            ) : null}
          </div>
        </div>
      </Form>
    </>
  );
};

export default ClickerAssignUsersFilters;

const reportPipelineTableColumns = [
  {
    id: 'status',
    label: 'Status',
    minWidth: 150,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'pipeline_id',
    label: 'Id',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'transaction_id',
    label: 'Transaction Id',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'branch',
    label: 'Branch',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'grade',
    label: 'Grade',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'section',
    label: 'Section',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'subject',
    label: 'Subject',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'duration',
    label: 'Duration',
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'center',
    labelAlign: 'center',
  },
];

const reportStatusTableColumns = [
  {
    id: 'branch',
    label: 'Branch',
    minWidth: 50,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'grade',
    label: 'Grade',
    minWidth: 170,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    labelAlign: 'center',
  },
];

export { reportPipelineTableColumns, reportStatusTableColumns };

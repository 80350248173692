import React from 'react';
import { Modal, Image } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const QuestionDetailsModal = ({
  questionViewModal,
  handleCloseViewQuestion,
  currentQuestion,
}) => {
  return (
    <div>
      <Modal
        visible={questionViewModal}
        centered
        zIndex={2900}
        footer={false}
        onCancel={handleCloseViewQuestion}
        title={<span className='pl-3'>Question Preview</span>}
        className='th-upload-modal'
        width={'70vw'}
      >
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <div className='mcq-container px-3'>
            <div className=''>
              <div className='d-flex justify-content-between'>
                <div className='th-16 th-fw-600 th-black-1 '>Question </div>
              </div>

              <div className='question-tiny text-justify my-2'>
                {ReactHtmlParser(currentQuestion?.question)}
              </div>

              <div className='th-16 th-fw-600 th-black'>Options</div>
              <div className='row'>
                {currentQuestion?.options?.map((item, i) => {
                  return (
                    <div className='col-md-6 mb-2 pl-0' key={i}>
                      <div
                        className={`text-justify p-2 ${
                          item?.is_answer && 'th-clicker-correct-answer'
                        }`}
                      >
                        {`${String.fromCharCode(65 + i)}. `}
                        {`${item.text}`}

                        {item?.image_url && (
                          <>
                            <br />
                            <Image
                              src={item?.image_url}
                              alt={item?.text ?? 'options'}
                              style={{ height: 80, width: 'auto' }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <p>
                <small>
                  *Note: The correct answer is/are highlighted with a green border.
                </small>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuestionDetailsModal;
